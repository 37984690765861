import { Injectable } from "@angular/core";
import { MerchantService } from "@core/merchant/merchant.service";
import { CustomPage, Merchant } from "@core/merchant/merchant.types";
import { Navigation } from "@core/navigation/navigation.types";
import { FuseNavigationItem } from "@fuse/components/navigation/navigation.types";
import { TranslocoService } from "@jsverse/transloco";
import { cloneDeep, isEqual } from "lodash-es";
import { combineLatest, distinctUntilChanged, Observable, of, ReplaySubject } from "rxjs";
import { horizontalNavigation, verticalNavigation } from "./navigation.data";
import { User as FirebaseUser, User } from 'firebase/auth';
import { UserService } from "@core/user/user.service";
import { CategoryService } from "@core/category/category.service";
import { Category } from "@core/category/category.types";
import { Router } from "@angular/router";
import { ProductService } from "@core/product/product.service";
import { ProductCount, ProductType } from "@core/product/product.types";
import { BlogService } from "@core/blog/blog.service";

/**
 * NavigationService is responsible for managing the navigation items of the application.
 * It provides access to the navigation items and updates them based on the user's role and the current state of the application.
 */
@Injectable({
  providedIn: "root",
})
export class NavigationService {

  /**
   * ProductType enum.
   */
  ProductType = ProductType;

  /**
   * ReplaySubject that emits the current navigation state.
   */
  private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

  /**
   * Array of vertical navigation items.
   */
  private _verticalNavigation: FuseNavigationItem[] = verticalNavigation;

  /**
   * Array of horizontal navigation items.
   */
  private _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;

  /**
   * Translation key for horizontal navigation items.
   */
  private _translocoReadHorizontal: string = "navigation.horizontal";

  /**
   * Translation key for vertical navigation items.
   */
  private _translocoReadVertical: string = "navigation.vertical";

  /**
   * The current merchant.
   */
  private _merchant: Merchant;

  /**
   * The number of services.
   */
  private _nServices: number = 0;

  /**
   * The number of blogs.
   */
  private _nBlogs: number = 0;

  /**
   * Array of categories.
   */
  private _categories: Category[];

  /**
   * The current language.
   */
  private _currentLanguage: string;

  /**
   * The current user.
   */
  private _user: FirebaseUser;

  /**
   * Constructor
   * @param _merchantService MerchantService instance.
   * @param _categoryService CategoryService instance.
   * @param _productService ProductService instance.
   * @param _blogService BlogService instance.
   * @param _userService UserService instance.
   * @param _router Router instance.
   * @param _translocoService TranslocoService instance.
   */
  constructor(
    private _merchantService: MerchantService,
    private _categoryService: CategoryService,
    private _productService: ProductService,
    private _blogService: BlogService,
    private _userService: UserService,
    private _router: Router,
    private _translocoService: TranslocoService
  ) {
    combineLatest([
      this._merchantService.merchant$,
      this._userService.user$,
      this._categoryService.categories$
    ])
      .pipe(
        distinctUntilChanged((previous: any, current: any) => isEqual(previous, current))
      )
      .subscribe(([merchant, user, categories]: [Merchant, User, Category[]]) => {
        // Work with a copy of the merchant
        this._merchant = cloneDeep(merchant);
        // Work with a copy of the user
        this._user = cloneDeep(user);
        // Work with a copy of the categories
        this._categories = cloneDeep(categories);
        // Update the navigation items
        this._updateNavigationItems();
        // Get the number of services
        if (this._merchant) {
          this._productService.countProducts({ 
              type: ProductType.Service
            }, {
              cache: true,
              propagate: false,
              forcePropagate: false
            })
            .subscribe((count: ProductCount) => {
              this._nServices = count.nbProducts;
              this._updateNavigationItems();
            });

          this._blogService.countBlogs({})
            .subscribe((count: number) => {
              this._nBlogs = count;
              this._updateNavigationItems();
            });
        }
      });

    // Subscribe to language changes
    this._translocoService.langChanges$
      .subscribe((activeLang) => {
        setTimeout(() => {
          this._currentLanguage = activeLang;
          this._updateNavigationItems();
        }, 500);
      });

    setTimeout(() => {
      this._updateNavigationItems();
    }, 2000);
    
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Returns an observable of the current navigation state.
   * @returns An observable of the current navigation state.
   */
  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Recursively searches for a navigation item with the given id starting from the startItem.
   * @param startItem - The navigation item to start the search from.
   * @param id - The id of the navigation item to find.
   * @returns The navigation item with the given id, or null if it is not found.
   */
  private _findItem(startItem: FuseNavigationItem, id: string): FuseNavigationItem {
    if (startItem.id === id) {
      return startItem;
    }
    if (startItem.children && startItem.children.length > 0) {
      for (const child of startItem.children) {
        const foundItem: FuseNavigationItem = this._findItem(child, id);
        if (foundItem) {
          return foundItem;
        }
      }
    }
    return null;
  }

  /**
   * Finds a navigation item with the given id in the provided navigation array.
   * @param navigation The navigation array to search in.
   * @param id The id of the navigation item to find.
   * @returns The found navigation item or null if not found.
   */
  private _findNavigationItem(navigation: FuseNavigationItem[], id: string): FuseNavigationItem {
    const items: FuseNavigationItem[] = navigation
      .map((item) => this._findItem(item, id))
      .filter(item => item);
    return items.length > 0 ? items[0] : null;
  }

  /**
   * Translates all navigation items for both horizontal and vertical navigation menus.
   * @private
   * @returns {void}
   */
  private _translateAllNavigationItems(): void {
    this._translateNavigationItems(this._horizontalNavigation, this._translocoReadHorizontal);
    this._translateNavigationItems(this._verticalNavigation, this._translocoReadVertical);
  }

  /**
   * Updates the navigation items based on the current merchant and categories.
   * If either the merchant or categories are not set, the function returns early.
   * The function hides the order history item if the user is not logged in.
   * It also hides the book item in both the vertical and horizontal navigation if there are no services available.
   * The function updates the category item in the vertical navigation with the current categories and their child categories.
   * Finally, it translates all navigation items and updates the navigation subject with the new navigation items.
   */
  private _updateNavigationItems(): void {
    if (!this._merchant || !this._categories || !this._currentLanguage) {
      return;
    }

    // Hide the order history
    const historyItem = this._findNavigationItem(this._verticalNavigation, 'history');
    historyItem.hidden = (item) => this._user == null;

    // Hide the book item in both the vertical and horizontal navigation
    const bookItemVerticalNav = this._findNavigationItem(this._verticalNavigation, 'book');
    if (bookItemVerticalNav) {
      bookItemVerticalNav.hidden = (item) => this._nServices === 0;
    }
    const bookItemHorizontalNav = this._findNavigationItem(this._horizontalNavigation, 'book');
    if (bookItemHorizontalNav) {
      bookItemHorizontalNav.hidden = (item) => this._nServices === 0;
    }

    // Hide the blog item in both the vertical and horizontal navigation
    const blogItemVerticalNav = this._findNavigationItem(this._verticalNavigation, 'blogs');
    if (blogItemVerticalNav) {
      blogItemVerticalNav.hidden = (item) => this._nBlogs === 0;
    }
    const blogItemHorizontalNav = this._findNavigationItem(this._horizontalNavigation, 'blogs');
    if (blogItemHorizontalNav) {
      blogItemHorizontalNav.hidden = (item) => this._nBlogs === 0;
    }

    const categoryItem = this._findNavigationItem(this._verticalNavigation, 'menu-category');
    categoryItem.children = this._categories.filter(category => category.parentCategoryId == null || category.parentCategoryId === '').map((category) => {
      return {
        id: category._id.toString(),
        title: category.name,
        type: 'basic',
        function: () => {
          console.log('Clicked on category');
          this._router.navigate(['/products'], { queryParams: { categoryId: category._id.toString() } });
        }
      };
    });

    // Add the custom pages
    const customPages: CustomPage[] = this._merchant.customPages;
    if (customPages && Array.isArray(customPages)) {
      for (let i = 0; i < customPages.length; i++) {
        const customPage = customPages[i];
        const menuGroup = this._findNavigationItem(this._verticalNavigation, 'menu-group');
        let customPageItem = this._findNavigationItem(menuGroup.children, `custom-page-${i}`);
        if (!customPageItem) {
          menuGroup.children.push({
            id: `custom-page-${i}`,
            title: customPage.name,
            type: 'basic',
            function: () => this._router.navigate([`/personal-pages/${i}`])
          });
        } else {
          customPageItem.title = customPage.name;
        }
        customPageItem = this._findNavigationItem(this._horizontalNavigation, `custom-page-${i}`);
        if (!customPageItem) {
          this._horizontalNavigation.push({
            id: `custom-page-${i}`,
            title: customPage.name,
            type: 'basic',
            function: () => this._router.navigate([`/personal-pages/${i}`])
          });
        } else {
          customPageItem.title = customPage.name;
        }
      }
    }

    this._translateAllNavigationItems();

    this._navigation.next(cloneDeep({
      compact: this._horizontalNavigation,
      default: this._verticalNavigation,
      futuristic: this._horizontalNavigation,
      horizontal: this._horizontalNavigation
    }));
  }

  /**
   * Translates the navigation items recursively.
   * @param navigation The navigation items to translate.
   * @param prefix The prefix to use for translation keys.
   */
  private _translateNavigationItems(navigation: FuseNavigationItem[], prefix: string): void {
    navigation.forEach((item) => {
      this._translateNavigationItem(item, prefix);
    });
  }

  /**
   * Translates the given key using the Transloco service.
   * If the translation is not found, an empty string is returned.
   * @param key The translation key to be translated.
   * @returns The translated text or an empty string if the translation is not found.
   */
  private _translateText(key: string): string {
    const text: string = this._translocoService.translate(key);
    if (text === key) {
      return '';
    }
    return text;
  }

  /**
   * Translates a navigation item's title and subtitle using the provided prefix and ID.
   * If the navigation item has children, it recursively translates their titles and subtitles as well.
   * If the navigation item has an ID of 'menu-group' and a merchant is available, it sets the title to the merchant's display name and the subtitle to their email.
   * @param item The navigation item to translate.
   * @param prefix The prefix to use when looking up the translation keys.
   */
  private _translateNavigationItem(
    item: FuseNavigationItem,
    prefix: string
  ): void {
    if (item.id.startsWith('custom-page-')) {
      return;
    }
    item.title = this._translateText(`${prefix}.${item.id}.title`);
    if (item.subtitle) {
      item.subtitle = this._translateText(
        `${prefix}.${item.id}.subtitle`
      );
    }
    if (this._merchant && item.id === 'menu-group') {
      item.title = this._merchant.displayName;
      item.subtitle = this._merchant.email;
    }
    if (item.id !== 'menu-category' && item.children && item.children.length > 0) {
      this._translateNavigationItems(item.children, `${prefix}.${item.id}.children`);
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Returns an observable that emits the navigation object containing different navigation types.
   * @returns An observable that emits the navigation object.
   */
  get(): Observable<Navigation> {
    this._navigation.next({
      compact: this._horizontalNavigation,
      default: this._verticalNavigation,
      futuristic: this._horizontalNavigation,
      horizontal: this._horizontalNavigation
    });
    return of(null);
  }
}
