
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { Order } from '@core/order/order.types';
import { SessionService } from '@core/session/session.service';
import { SessionData } from '@core/session/session.type';
import { FuseDrawerComponent, FuseDrawerService } from '@fuse/components/drawer';
import { TranslocoModule } from '@jsverse/transloco';
import { BasketContentComponent } from '@shared/basket-content/basket.component';
import { cloneDeep } from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';

/**
 * Component that displays the user's basket.
 */
@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styles: [
    `
      app-book {
        position: static;
        display: block;
        flex: none;
        width: auto;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    FuseDrawerComponent,
    BasketContentComponent
],
})
export class BasketComponent implements OnInit, OnDestroy {
  
  /**
   * The user's order data.
   */
  order: Order;

  /**
   * Template data for the component.
   */
  tpl: {
    /**
     * The number of products in the user's basket.
     */
    nProducts: number,
  } = {
    nProducts: 0,
  }

  /** 
   * Subject that emits when the component has been destroyed.
  */
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   *
   * @param _sessionService - The `SessionService` instance.
   * @param _fuseDrawerService - The `FuseDrawerService` instance.
   * @param _changeDetectorRef - The `ChangeDetectorRef` instance.
   */
  constructor(
    private _sessionService: SessionService,
    private _fuseDrawerService: FuseDrawerService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * Lifecycle hook that is called after the component has been initialized.
   * Subscribes to the `data$` observable of the `SessionService` to get the user's basket data.
   */
  ngOnInit(): void {

    this._sessionService.data$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((sessionData: SessionData) => {
        this.order = cloneDeep(sessionData?.basket);
        this._updateTemplateData();
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  /**
   * Lifecycle hook that is called when the component is about to be destroyed.
   * Unsubscribes from all subscriptions.
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Updates the template data for the component.
   * Calculates the number of products in the user's basket.
   */
  private _updateTemplateData(): void {
    if (!this.order) {
      return;
    }
    this.tpl.nProducts = this.order.qartOrder.productOrders.reduce((acc, curr) => acc + curr.quantity, 0);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Closes the basket drawer.
   */
  closeDrawer() {
    this._fuseDrawerService.getComponent('basket').close();
  }

}
