import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { MerchantService } from '@core/merchant/merchant.service';
import { Merchant, MerchantColors } from '@core/merchant/merchant.types';
import { NavigationService } from '@core/navigation/navigation.service';
import { Navigation } from '@core/navigation/navigation.types';
import { UserService } from '@core/user/user.service';
import { environment } from '@env/environment';
import { FuseHorizontalNavigationComponent, FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Subject, takeUntil } from 'rxjs';
import { User as FirebaseUser } from 'firebase/auth';
import { User } from '@core/user/user.types';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { CommonModule, isPlatformBrowser, NgStyle } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { LanguagesComponent } from '@layout/common/languages/languages.component';
import { MessagesComponent } from '@layout/common/messages/messages.component';
import { NotificationsComponent } from '@layout/common/notifications/notifications.component';
import { SearchComponent } from '@layout/common/search/search.component';
import { UserComponent } from '@layout/common/user/user.component';
import { FooterComponent } from '@layout/common/footer/footer.component';
import { BasketComponent } from '@layout/common/basket/basket.component';
import { SafePipe } from '@core/utils/safe.pipe';


/**
 * The ModernLayoutComponent class represents the modern layout component of the merchant website.
 * It implements OnInit and OnDestroy lifecycle hooks.
 */
@Component({
    selector: 'modern-layout',
    templateUrl: './modern.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone   : true,
    imports      : [
        TranslocoModule,
        CommonModule,
        FuseLoadingBarComponent,
        FuseVerticalNavigationComponent,
        FuseHorizontalNavigationComponent,
        MatButtonModule,
        MatIconModule,
        LanguagesComponent,
        FuseFullscreenComponent,
        SearchComponent,
        MessagesComponent,
        NotificationsComponent,
        UserComponent,
        BasketComponent,
        FooterComponent,
        RouterOutlet,
        SafePipe
    ],
    providers    : [
        SafePipe
    ]
})
export class ModernLayoutComponent implements OnInit, OnDestroy {

    /**
     * The navigation data.
     */
    public navigation: Navigation;

    /**
     * The merchant data.
     */
    public merchant: Merchant | null = null;

    /**
     * The user data.
     */
    public user: User;

    /**
     * The template variables.
     */
    public tpl: {
        isBrowser: boolean;
        merchantColors: MerchantColors;
        isMobile: boolean;
        avatar: string,
        icon: string
        topBannerText: string,
        whatsappNumber: string,
    } = {
        isBrowser: false,
        merchantColors: null,
        isMobile: false,
        avatar: null,
        icon: null,
        topBannerText: null,
        whatsappNumber: null,
    };

    /**
     * The subject for unsubscribing from all subscriptions.
     */
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * The current language.
     */
    private _currentLanguage: string;

    /**
     * Constructor
     * @param _platformId The platform ID.
     * @param _navigationService The navigation service.
     * @param _fuseMediaWatcherService The media watcher service.
     * @param _fuseNavigationService The navigation service.
     * @param _merchantService The merchant service.
     * @param _translocoService The transloco service.
     * @param _userService The user service.
     * @param _changeDetectorRef The change detector reference.
     */
    constructor(
        @Inject(PLATFORM_ID) private _platformId: any,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _merchantService: MerchantService,
        private _translocoService: TranslocoService,
        private _userService: UserService,
        private _changeDetectorRef: ChangeDetectorRef,
    ) {
        this.tpl.isBrowser = isPlatformBrowser(this._platformId);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Returns the current year as a number.
     * @returns {number} The current year.
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * Lifecycle hook that is called after data-bound properties of a directive are initialized.
     * Subscribes to language changes, merchant changes, navigation data changes, media changes, and user changes.
     * Updates the UI and notifies the change detector accordingly.
     */
    ngOnInit(): void {

        // Subscribe to language changes
        this._translocoService.langChanges$
            .subscribe((activeLang) => {
                this._currentLanguage = activeLang;
                // Update the UI
                this._setTemplateVariables();
                // Notify the change detector
                this._changeDetectorRef.markForCheck();
            });
        
        // Get the merchant
        this._merchantService.merchant$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((merchant: Merchant | null) => {
                this.merchant = merchant;
                // Update the UI
                this._setTemplateVariables();
                // Notify the change detector
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.tpl.isMobile = !matchingAliases.includes('md');
                // Notify the change detector
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(async (user: FirebaseUser | null) => {
                if (user) {
                    // We need to wait a bit, otherwise the function gets called as soon as the user is authenticated on Firebase,
                    // but the sign-in procedure did not even have the time to record the access token
                    setTimeout(async () => {
                        this.user = await this._userService.getQartUser();
                        // Mark for check
                        this._changeDetectorRef.markForCheck();
                    }, 1000);
                }
            });
    }

    /**
     * Lifecycle hook that is called when the component is destroyed.
     * Unsubscribes from all subscriptions to prevent memory leaks.
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sets the template variables for the ModernComponent.
     * If the merchant or current language is not available, it returns.
     * Sets the merchant colors, avatar, icon, and top banner text.
     * Notifies the change detector.
     */
    private _setTemplateVariables(): void {
        if (!this.merchant || !this._currentLanguage) {
            return;
        }
        this.tpl.merchantColors = this._merchantService.getColors(this.merchant);
        this.tpl.avatar = this._merchantService.getAvatarUrl();
        if (this.merchant.favicon) {
            this.tpl.icon = `${environment.qart.imagesUrl}/${this.merchant.name}/${this.merchant.favicon.filename}`;
        }
        this.tpl.topBannerText = this.merchant.website.text[this._currentLanguage].home?.topBannerText?.text || '';
        if (this.merchant.whatsappNumber && this.merchant.whatsappNumber !== '') {
            // Remove all the non-digit characters from the phone number
            this.tpl.whatsappNumber = this.merchant.whatsappNumber.replace(/\D/g, '');
        }
        // Notify the change detector
        this._changeDetectorRef.markForCheck();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggles the opened status of the specified navigation component.
     * 
     * @param name - The name of the navigation component to toggle.
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    /**
     * Called when a component is activated.
     * Pushes the page name to the dataLayer array if it exists.
     * @param event - The event that triggered the activation.
     * @param outlet - The outlet where the component was activated.
     */
    onActivate(event, outlet) {
        if (isPlatformBrowser(this._platformId)) {
            if (window["dataLayer"]) {
                window["dataLayer"].push({ 
                    page_name: outlet.activatedRoute.snapshot.data?.pageName
                });
            }
        }
    }
}
