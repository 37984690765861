import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { MerchantService } from '@core/merchant/merchant.service';
import { Merchant } from '@core/merchant/merchant.types';
import { environment } from '@env/environment';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';


/**
 * Service for retrieving the frontend version number.
 */
@Injectable({
    providedIn: 'root'
})
export class VersionService {

    /**
   * The version number.
   */
    private _version: BehaviorSubject<string> = new BehaviorSubject(null);

    /**
     * The timer for checking the version number.
     */
    private _checkTimer: NodeJS.Timeout;

    /**
     * Whether the timer has fired.
     */
    private _checkTimerFired: boolean;

    /**
   * The merchant associated with the blogs.
   */
    private _merchant: Merchant;

    /**
     * Creates an instance of VersionService.
     * @param _httpClient The HttpClient service.
     */
    constructor(
        private _httpClient: HttpClient,
        private _merchantService: MerchantService,
        private _metaTagService: Meta,
    ) {

        this._checkTimer = null;
        this._checkTimerFired = false;

        // Get the merchant
        this._merchantService.merchant$
            .subscribe((merchant: Merchant) => {
                const reload = this._merchant?._id.toString() !== merchant?._id.toString();
                this._merchant = merchant;
                if (reload) {
                    this.getVersion().subscribe();
                }
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for the version number.
     * @returns An observable that emits the version number.
     */
    get version$(): Observable<string> {
        return this._version.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    _checkVersion(version: string): void {
        // Checking for a new version
        if (!version) {
            // No version number provided. Nothing to check.
            return;
        }
        if (this._version.value && version && this._version.value === version) {
            // Nothing to check.
            return;
        }
        // Get the current version number from the metadata of the HTML file
        const metaTag: HTMLMetaElement = this._metaTagService.getTag('name="version"');
        if (!metaTag) {
            // Version meta tag not found on the page.
            if (this._checkTimer) {
                // The retry timer has been set already.
                if (this._checkTimerFired) {
                    // The retry timer has fired already. Proceeding with the update.
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.reload();
                } else {
                    // The timer hasn't fired yet. Waiting for the timer to fire.
                }
            } else {
                // The retry timer hasn't been set yet. Setting the timer: 10 seconds.
                this._checkTimer = setTimeout(() => {
                    this._checkTimerFired = true;
                    this._checkVersion(version);
                    this._checkTimer = null;
                }, 10000);
            }
        } else {
            // Version meta tag found on the page.
            const currentVersion = metaTag.content;
            // Version installed: " + currentVersion
            // Latest version: " + version
            // If the version number has changed, reload the page
            if (version && version !== currentVersion) {
                console.log("Installing the new version: " + version);
                localStorage.clear();
                sessionStorage.clear();
                window.location.reload();
            } else {
                console.log("Website is up to date: version " + currentVersion + " installed.");
                this._version.next(currentVersion);
            }
        }

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Gets the version number of the frontend.
     * @returns An observable that emits the version number.
     */
    getVersion(): Observable<string> {
        if (!environment.ssr) {
            return of('2.13.1');
        }
        if (!this._merchant) {
            return of(null);
        }
        let url = `https://${this._merchant.name}.${environment.qart.domain}/version`;
        if (environment.qart.domain === 'localhost') {
            url = `http://${environment.qart.domain}:4200/version`;
        }
        return this._httpClient.get<{ version: string }>(
            url,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log('Error:', error);
                    return of(null);
                }),
                map((response: { version: string }) => response?.version ? response.version : null),
                tap((version: string) => this._checkVersion(version))
            );
    }
}